





















import { computed, defineComponent, ref, useFetch } from '@nuxtjs/composition-api';
import { getVideoUrl } from '@amplience/helpers/getVideoUrl';

export default defineComponent({
  name: 'VideoAmplience',
  props: {
    videoOrientation: {
      type: String,
      default: 'landscape', // 'portrait' or 'landscape'
    },
    caption: {
      type: String,
      default: '',
    },
    video: {
      type: Object,
      default: () => ({}),
    },
    profileName: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const videoContent = ref(null);

    useFetch(async () => {
      const response = await fetch(getVideoUrl(props.video));
      videoContent.value = await response.text();
    });

    return {
      videoContent,
      orientationClass: computed(() => props.videoOrientation?.toLowerCase() || ''),
    };
  },
});
